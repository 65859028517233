<template>
  <b-nav-item>
    <metamask-logo
      style="width:28px"
      badge="4"
      badge-classes="badge-danger badge-glow"
    />
    <b-badge
      pill
      variant="success"
      class="badge-glow badge-up"
      style="width: 10px !important; top:0px !important"
    >
&nbsp;
    </b-badge>
  </b-nav-item>
</template>

<script>
import { BNavItem, BBadge } from 'bootstrap-vue'
import MetamaskLogo from '../../LogoMetamask.vue'

export default {
  components: {
    BNavItem,
    BBadge,
    MetamaskLogo,
  },
}
</script>
