<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItemsF" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  // setup() {
  //   return {
  //     navMenuItems,
  //   }
  // },
  data() {
    const sessionData = this.$sessionData.get()
    let navMenuItemsFinal = navMenuItems
    if (!sessionData.isGm) { // si no es una empresa quitamos el item de la lista
      for (let i = 0; i < navMenuItems.length; i += 1) {
        if (navMenuItems[i].icon === 'TrendingUpIcon') {
          navMenuItemsFinal = navMenuItems.filter(item => item.icon !== 'TrendingUpIcon')
        }
      }
    }
    return {
      navMenuItemsF: navMenuItemsFinal,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
