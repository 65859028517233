export default [
  {
    icon: 'TrelloIcon',
    title: 'Liquidity & Swap',
    route: '/',
  },
  {
    header: 'Products & Services',
    icon: 'ShoppingBagIcon',
    hidden: false,
  },
  {
    title: 'Shop',
    route: 'e-commerce-list',
    icon: 'TrelloIcon',
  },
  {
    title: 'My Inventory',
    route: { name: 'my-genetic', params: { slug: '' } },
  },
  {
    title: 'Shop Cart',
    route: 'apps-e-commerce-checkout',
  },
  // {
  //   title: 'P2P & SWAP',
  //   icon: 'ShieldIcon',
  //   route: 'market',
  //   // children: [
  //   //   {
  //   //     title: 'P2P & SWAP',
  //   //     route: 'market',
  //   //   },
  //   // ],
  // },
  {
    header: 'Wallet',
    icon: 'ShoppingBagIcon',
    hidden: false,
  },
  {
    title: 'Profile',
    icon: 'UserIcon',
    route: 'wallet',
    // children: [
    //   {
    //     title: 'Profile',
    //     route: 'wallet',
    //   },
    // ],
  },
  {
    title: 'B2B Solutions',
    icon: 'BriefcaseIcon',
    route: 'fabric',
  },
  // {
  //   title: 'Market',
  //   route: 'e-commerce-list',
  //   icon: 'TrelloIcon',
  // },
  // {
  //   title: 'Your Inventory',
  //   route: 'my-genetic',
  //   icon: 'BriefcaseIcon',
  // },
  // {
  //   title: 'Cannaverse',
  //   // route: 'wallet',
  //   icon: 'GlobeIcon',
  // },
  // {
  //   title: 'Wallet',
  //   route: 'wallet',
  //   icon: 'CodepenIcon',
  // },
  // {
  //   title: 'KYC',
  //   route: 'kyc',
  //   icon: 'UserCheckIcon',
  // },
  // {
  //   title: 'For Business...',
  //   icon: 'none',
  //   color: 'green',
  // },
  // {
  //   title: 'Edit Company',
  //   route: 'your-company',
  //   icon: 'SlidersIcon',
  // },
  // {
  //   title: 'Ugrade Business',
  //   route: 'fabric',
  //   icon: 'ToolIcon',
  // },
  // {
  //   title: 'Product / Service',
  //   // route: 'e-project-list',
  //   icon: 'PlusIcon',
  // },
  // {
  //   title: 'The Cannaverse',
  //   route: 'cannaverse',
  //   icon: 'GlobeIcon',
  // },
  /*
  {
    title: 'My Green House',
    route: 'e-project-list',
    icon: 'BoxIcon',
  },
  {
    title: 'Wallet',
    route: 'second-page',
    icon: 'FileIcon',
  },
  */
]
