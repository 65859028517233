<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <!-- <li
            class="nav-item mr-auto"
            style="width: 90%">
            <b-link
              class="navbar-brand"
              style="display: contents"
              to="/"
            >
              <span class="brand-logo">
                <header-egroweed-nft
                  v-show="!isDark"
                  style="width:180px; height:46px"
                  class="w-100"
                />
                <logo-bancannabis-black
                  v-show="isDark"
                  style="width:180px; height:46px"
                  class="w-100"
                />
              </span>
            </b-link>
          </li> -->
          <!-- <dark-Toggler
            class="nav-item mr-auto d-lg-block"
            style="top: 14px; position: relative;" /> -->
          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />
    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <!--<card-advance-profile
        :projectcount="1"
        :usdtbalance="0"
        :bcabalance="0"
        :usrname="usrName"
        :usrlastname="usrLastName"
        :kycstate="kycState"
        class="mt-1"
      />-->
      <!-- <select-network v-show="isMobile()" /> -->
      <vertical-nav-menu-items
        :items="navMenuItemsF"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
// import SelectNetwork from '@core/layouts/components/SelectNetwork.vue'
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
// import LogoBancannabisBlack from '@core/layouts/components/LogoBancannabisBlack.vue'
// import HeaderEgroweedNft from '@core/layouts/components/HeaderLoginNft.vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
// import CardAdvanceProfile from '@/views/cards/CardAdvanceProfile.vue'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    // BImg,
    // SelectNetwork,
    // CardAdvanceProfile,
    // LogoBancannabisBlack,
    // DarkToggler,
    // HeaderEgroweedNft,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 40,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    const isDark = computed(() => skin.value === 'dark')

    return {
      userData: null,
      usrName: '',
      usrLastName: '',
      kycState: 0,
      kycStates: {
        noKyc: 0,
        pending: 1,
        verify: 2,
        incomplete: 3,
      },
      updateKycInterval: null,
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,
      isDark,

      // App Name
      appName,
      appLogoImage,
    }
  },
  data() {
    const sessionData = this.$sessionData.get()
    let navMenuItemsFinal = navMenuItems
    if (!sessionData.isGm) { // si no es una empresa quitamos el item de la lista
      for (let i = 0; i < navMenuItems.length; i += 1) {
        if (navMenuItems[i].icon === 'TrendingUpIcon') {
          navMenuItemsFinal = navMenuItems.filter(item => item.icon !== 'TrendingUpIcon')
        }
      }
    }
    return {
      navMenuItemsF: navMenuItemsFinal,
    }
  },
  created() {
    this.$sessionData.setContext(this)
    this.verifyKyc()
    this.updateKycInterval = setInterval(() => {
      this.verifyKyc()
      if (this.kycState === this.kycStates.verify) {
        clearInterval(this.updateKycInterval)
      }
    }, 40000)
  },
  methods: {
    isMobile() {
      return (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
    },
    verifyKyc() {
      if (this.$sessionData.validateSession()) {
        this.$sessionData.updateKycSession()
          .then(userDataCopyObject => {
            this.usrName = userDataCopyObject.data.kyc.name
            this.usrLastName = userDataCopyObject.data.kyc.lastName
            // Si la cuenta ya esta verificada
            if (userDataCopyObject.data.kyc.accountActive !== '0') {
              this.kycState = this.kycStates.verify
              return
            }

            // verificamos el estado pendiente (en caso de que solo haya llenado los datos dle formulario pero no adjuntado las imagenes)
            if ((!userDataCopyObject.data.kyc.sourceDocument1 || !userDataCopyObject.data.kyc.sourceDocument2) && userDataCopyObject.data.kyc.name !== '') {
              this.kycState = this.kycStates.incomplete
            }

            // si ingreso todos los datos e imagenes pero aun no se ha verificado
            if (userDataCopyObject.data.kyc.name !== '' && (userDataCopyObject.data.kyc.sourceDocument1 && userDataCopyObject.data.kyc.sourceDocument2) && userDataCopyObject.data.kyc.accountActive === '0') {
              this.kycState = this.kycStates.pending
            }
          })
          .catch(() => false)
      } else if (this.$router.currentRoute.name !== 'auth-register' && this.$router.currentRoute.name !== 'login') {
        // this.$router.push('/login').catch(() => {})
      }
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
