<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © 2018 - {{ new Date().getFullYear() }}
      <b-link
        v-if="!isZero"
        class="ml-25"
        href="https://bancannabis.org"
        target="_blank"
      >Bancannabis.org</b-link>
      <b-link
        v-if="isZero"
        class="ml-25"
        href="https://zerocarnizero.com"
        target="_blank"
      >ZeroCarniZero.com</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block">Hand-crafted &amp; Made by<bancannabis-logo /> with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import BancannabisLogo from '@core/layouts/components/LogoBancannabis.vue'

export default {
  components: {
    BLink,
    BancannabisLogo,
  },
  data() {
    return {
      isZero: this.$sessionData.get().isZero,
    }
  },
  // created() {
  //   console.log(this.$route.params)
  // },
}
</script>
