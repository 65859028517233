export default [
  {
    icon: 'TrelloIcon',
    title: 'Liquidity & Swap',
    route: '/',
  },
  {
    title: 'Products & Services',
    header: 'Products & Services',
    icon: 'ShoppingBagIcon',
    hidden: false,
    children: [
      {
        title: 'Shop',
        route: 'e-commerce-list',
      },
      {
        title: 'Inventory',
        route: { name: 'my-genetic', params: { slug: '' } },
      },
      {
        title: 'Shop Cart',
        route: 'apps-e-commerce-checkout',
      },
    ],
  },
  // {
  //   title: 'P2P',
  //   icon: 'BriefcaseIcon',
  //   route: 'market',
  //   // children: [
  //   //   {
  //   //     title: 'P2P & SWAP',
  //   //     route: 'market',
  //   //   },
  //   // ],
  // },
  {
    title: 'Wallet',
    icon: 'UserIcon',
    route: 'wallet',
    // children: [
    //   {
    //     title: 'Profile',
    //     route: 'wallet',
    //   },
    // ],
  },
  {
    title: 'B2B Solutions',
    icon: 'BriefcaseIcon',
    route: 'fabric',
  },
]
