<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="nav bookmark-wrapper align-items-center flex-grow-2 d-lg-flex">
      <dark-Toggler class=" d-lg-block" />
    </div>

    <div class="nav align-items-center ml-auto">
      <b-link
        class="navbar-brand"
        style="display: contents"
        to="/"
      >
        <header-egroweed-nft
          v-show="!isDark"
          style="width:180px; height:46px"
          class="w-100"
        />
        <logo-bancannabis-black
          v-show="isDark"
          style="width:180px; height:46px"
          class="w-100"
        />
      </b-link>
    <!--  <div style="width:180px;">
        <b-link
          class="nav-link"
          @click="logoClick()"
        >

          <header-egroweed-nft
            v-if="!usrData.isZero"
            v-show="!isDark"
            style="width:180px; height:46px"
            class="w-100"
          />
          <logo-bancannabis-black
            v-if="!usrData.isZero"
            v-show="isDark"
            style="width:180px; height:46px"
            class="w-100"
          />
          <zerocarnizero-Logo
            v-if="usrData.isZero"
            style="width:180px; height:46px"
            class="w-100"
          />
        </b-link>
      </div>-->
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <BcaBalance
        :onlybca="true"
      /> -->
      <b-form
        v-show="false"
        class="auth-login-form"
        @submit.prevent
      >
        <b-form-group
          label-for="blockchain"
          label="Blockchain Network"
        >
          <v-select
            v-model="blockchainId"
            label="title"
            style="min-width:225px"
            :options="blockchains"
            @input="addNetwork()"
          >
            <template #option="{ title, icon }">
              <feather-icon
                :icon="icon"
                size="16"
                class="align-middle mr-50"
              />
              <span> {{ title }}</span>
            </template>
          </v-select>
        </b-form-group>
      </b-form>
      &nbsp;&nbsp;&nbsp;
      <metamask-status
        v-show="doMetamaskRequest"
        title="You're using Metamask"
      />
      &nbsp;&nbsp;&nbsp;
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <!-- <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              Settings
            </p>
            <span class="user-status">and options</span>
          </div> -->
          <feather-icon
            badge
            size="16"
            icon="SettingsIcon"
            class="mr-50"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="goProfile"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="goInventory"
        >
          <feather-icon
            size="16"
            icon="ShoppingBagIcon"
            class="mr-50"
          />
          <span>Inventory</span>
        </b-dropdown-item>

        <b-dropdown-divider /> -->
        <!-- <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="gotowalletbca"
        >
          <feather-icon
            size="16"
            icon="CreditCardIcon"
            class="mr-50"
          />
          <span>wallet</span>
        </b-dropdown-item> -->
        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="gotocart"
        >
          <feather-icon
            size="16"
            icon="ShoppingCartIcon"
            class="mr-50"
          />
          <span>Cart</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <!-- Network Change -->
    <b-modal
      id="modal-network-change"
      centered
      ok-only
      no-close-on-backdrop
      title="Network Change"
    >
      <b-col>
        <span class="text-center">
          The network has been changed!, reloading page to upload blockchain values
        </span>
      </b-col>
    </b-modal>

    <!-- Account Change -->
    <b-modal
      id="modal-account-change"
      centered
      ok-only
      no-close-on-backdrop
      title="Account Change"
      @ok="logout"
    >
      <b-col>
        <span class="text-center">
          THE ACCOUNT HAS BEEN CHANGED!, PLEASE RELOG TO VERIFY YOUR IDENTITY
        </span>
      </b-col>
    </b-modal>

  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
// import { computed } from '@vue/composition-api'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BFormGroup, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import BcaBalance from '@/@core/layouts/components/BcaBalance.vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import MetamaskStatus from '@core/layouts/components/app-navbar/components/MetamaskStatus.vue'

import { $themeConfig } from '@themeConfig'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
// import VuexyLogoDark from '@core/layouts/components/LogoDark.vue'
import HeaderEgroweedNft from '@core/layouts/components/HeaderLoginNft.vue'
import LogoBancannabisBlack from '@core/layouts/components/LogoBancannabisBlack.vue'
// import ZerocarnizeroLogo from '@core/layouts/components/LogoZeroCarniZero.vue'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    // Navbar Components
    DarkToggler,
    MetamaskStatus,
    // VuexyLogo,
    // VuexyLogoDark,
    HeaderEgroweedNft,
    LogoBancannabisBlack,
    // ZerocarnizeroLogo,
    // BcaBalance,
    vSelect,
    BFormGroup,
    BForm,
  },
  setup() {
    // App Name
    const { skin } = useAppConfig()
    const { appLogoImage } = $themeConfig.app
    const isDark = computed(() => skin.value === 'dark')

    return { appLogoImage, skin, isDark }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      // isZero: false,
      usrData: null,
      doMetamaskRequest: false,
      // isDark: true,
      blockchainName: null,
      blockchainId: {
        title: 'Binance (BSC) Mainnet',
        icon: 'BoxIcon',
        id: 0x38,
        name: 'Binance Coin',
        provider: 'https://bsc-dataseed.binance.org/',
        scanner: 'https://bscscan.com',
        symbol: 'BNB',
      },
      // {
      //   title: 'GANACHE Mainnet',
      //   icon: 'BoxIcon',
      //   id: 5777,
      //   name: 'GANACHE',
      //   provider: 'HTTP://127.0.0.1:7545',
      //   scanner: '',
      //   symbol: 'BNB',
      // },
      blockchains: [
        {
          title: 'Binance (BSC) Mainnet',
          icon: 'BoxIcon',
          id: 0x38,
          name: 'Binance Coin',
          provider: 'https://bsc-dataseed.binance.org/',
          scanner: 'https://bscscan.com',
          symbol: 'BNB',
        },
        // {
        //   title: 'Ethereum Mainnet',
        //   icon: 'BoxIcon',
        //   id: 0x1,
        //   name: 'Ether',
        //   provider: 'https://mainnet.infura.io/v3/',
        //   scanner: 'https://etherscan.io',
        //   symbol: 'ETH',
        // },
        // {
        //   title: 'Matic Mainnet',
        //   icon: 'BoxIcon',
        //   id: 0x89,
        //   name: 'Polygon Coin',
        //   provider: 'https://rpc-mainnet.maticvigil.com/',
        //   scanner: 'https://bscscan.com',
        //   symbol: 'MATIC',
        // },
        // {
        //   title: 'GANACHE Mainnet',
        //   icon: 'BoxIcon',
        //   id: 5777,
        //   name: 'GANACHE',
        //   provider: 'HTTP://127.0.0.1:7545',
        //   scanner: '',
        //   symbol: 'BNB',
        // },
        // {
        //   title: 'Binance Smart Chain (TESTNET)',
        //   icon: 'BoxIcon',
        //   id: 0x61,
        //   name: 'Binance Coin',
        //   provider: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        //   scanner: 'https://bscscan.com',
        //   symbol: 'BNB',
        // },
        // {
        //   title: 'CELO Mainnet',
        //   icon: 'BoxIcon',
        //   id: 0xA4EC,
        //   name: 'CELO',
        //   provider: 'https://celo-mainnet.infura.io',
        //   scanner: 'https://celoscan.io',
        //   symbol: 'CELO',
        // },
      ],
    }
  },
  async created() {
    // this.goToPortal() // temporal redirection
    this.usrData = this.$sessionData.get()
    // if (this.usrData.isZero) {
    //   setTimeout(() => {
    //     try {
    //       document.querySelector('.nav-link').style.display = 'none'
    //       document.querySelector('.modern-nav-toggle').style.display = 'none'
    //       document.querySelector('.main-menu-content').style.display = 'none'
    //       // document.querySelector('.menu-accordion').style.display = 'none'
    //     } catch (e) {
    //       /* eslint-disable no-empty */
    //     }
    //   }, 2000)
    // }
    this.blockchainId = this.$sessionData.getCurrentNetworkData()
    if (!this.blockchainId.name) {
      this.logout()
      return
    }
    if (this.usrData.metamaskSigin) {
      this.doMetamaskRequest = true
      window.ethereum.on('networkChanged', this.onNetworkChange)
      window.ethereum.on('accountsChanged', this.onAccountChange)
      let accountMetamask = window.web3.currentProvider.selectedAddress
      if (!accountMetamask) {
        accountMetamask = (this.usrData.data.kyc.wallet) ? this.usrData.data.kyc.wallet : this.usrData.data.invitation.walletAddress
      }
      if (!accountMetamask) {
        this.logout()
        return
      }
      const sessionWallet = (this.usrData.data.kyc.wallet) ? this.usrData.data.kyc.wallet : this.usrData.data.invitation.wallet
      if (sessionWallet) {
        if (sessionWallet.toLowerCase() !== accountMetamask.toLowerCase()) {
          this.logout()
        }
      } else {
        this.logout()
      }
    }
  },
  methods: {
    gotowalletbca() {
      this.$router.push({ name: 'wallet', params: { iz: 0 } })
    },
    gotocart() {
      this.$router.push({ name: 'apps-e-commerce-checkout' })
    },
    logout() {
      // Remove userData from localStorage
      localStorage.removeItem('userData')
      // Redirect to login page
      this.$router.push({ name: 'login', params: { iz: 0 } })
    },
    goToPortal() {
      this.$router.push({ name: 'portal' })
    },
    isMobile() {
      return (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
    },
    onNetworkChange(networkId) {
      // Si la blockchain seleccionada no existe
      if (!this.blockchains.filter(blockchain => blockchain.id === networkId)) {
        this.logout()
      } else {
        this.$sessionData.setNetwork(networkId)
        this.$bvModal.show('modal-network-change')
        this.$router.push({ name: '/' })
        // window.location.reload()
      }
    },
    onAccountChange() {
      this.$bvModal.show('modal-account-change')
    },
    openModal(id) {
      this.$bvModal.show(id)
    },
    closeModal(id) {
      this.$bvModal.hide(id)
    },
    goProfile() {
      // Redirect to login page
      this.$router.push({ name: 'wallet' })
    },
    goInventory() {
      // Redirect to login page
      this.$router.push({ name: 'my-genetic' })
    },
    logoClick() {
      // Redirect to home page
      this.$router.push({ name: '/' })
    },
    addNetwork() {
      if (this.doMetamaskRequest) {
        return this.addNetworkToMetamask()
      }
      // actualizar la red en login bca
      this.$sessionData.setNetwork(this.blockchainId.id)
      window.location.reload()
      // this.$router.push({ name: 'login' })
      // this.$router.push({ name: '/' })
      return false
    },
    async addNetworkToMetamask() {
      // nuevo ID de la cadena
      let newChainId = this.blockchainId.id

      if (newChainId === 56) newChainId = '0x38'
      else if (newChainId === 1) newChainId = '0x'
      else if (newChainId === 137) newChainId = '0x89'
      else newChainId = '0x61'
      const response = await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: newChainId,
          chainName: this.blockchainId.title,
          nativeCurrency: {
            name: this.blockchainId.name,
            symbol: this.blockchainId.symbol,
            decimals: 18,
          },
          rpcUrls: [this.blockchainId.provider],
          blockExplorerUrls: [this.blockchainId.scanner],
        }],
      })

      if (!response) return false

      this.$sessionData.setNetwork(newChainId)
      window.location.reload()
      return true
    },
  },
}
</script>
